import React, { useEffect, useRef, useState } from 'react'
import './Games.css'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useSelector } from 'react-redux'
import { CAccordionItem, CAccordionBody, CAccordionHeader, CAccordion } from '@coreui/react'
import { TablePagination } from '@mui/material'
import { useGetAllUsersQuery } from '../APISlices/userapiSlice'
import Calendar from 'react-calendar'
import moment from 'moment'
import { format, addDays, subDays, addWeeks, subWeeks, addMonths, subMonths, startOfWeek, endOfWeek, startOfMonth, endOfMonth ,isAfter} from 'date-fns';
import CIcon from '@coreui/icons-react'
import { cilArrowThickLeft, cilArrowThickRight } from '@coreui/icons'


const SearchIcon = (
  <svg
    className="w-6 h-6 text-gray-800 dark:text-white"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="#175676"
      strokeLinecap="round"
      strokeWidth="2"
      d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
    />
  </svg>
)


const Games = () => {
    const allGames = useSelector((state) => state.allGames)
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [myGameData, setMyGameData] = useState();
    const [openDropdwon, setOpenDropdwon] = useState(false)
    const [isDateFilterApplied, setIsDateFilterApplied] = useState(false);
    const { data, isLoading } = useGetAllUsersQuery()
    const [filteredData, setFilteredData] = useState(null)
    const [selectedMode, setSelectedMode] = useState('all');
    const [selectedCompletion, setSelectedCompletion] = useState('all')
    const [searchTerm, setSearchTerm] = useState('')
    const [filteredSearchData, setFilteredSearchData] = useState(null)
    const [selectedFilterDate, setSelectedFilterDate] = useState(new Date())
    const [selectedToFilterDate, setSelectedToFilterDate] = useState(new Date())
    const [formatedDate, setFormatedDate] = useState()
    const [formatedToDate, setFormatedToDate] = useState()
    const [isClickedFromDate, setIsClickedFromDate] = useState(false)
    const [isClickedToDate, setIsClickedToDate] = useState(false)
    const wrapperRefCalendar = useRef(null)
    let rows = []


    useEffect(() => {
      const originalDate = new Date(selectedFilterDate)
      // const formattedDate = moment(originalDate).format('MM-DD-YYYY')
      // setFormatedDate(formattedDate)
      const formattedDate = format(originalDate, 'MM-dd-yyyy')
      setFormatedDate(formattedDate)
    }, [setSelectedFilterDate, selectedFilterDate])
    
      useEffect(() => {
        const originalDate = new Date(selectedToFilterDate)
        // const formattedDate = moment(originalDate).format('MM-DD-YYYY')
        // setFormatedToDate(formattedDate)
        const formattedDate = format(originalDate, 'MM-dd-yyyy')
        setFormatedToDate(formattedDate)
      }, [selectedToFilterDate, setSelectedToFilterDate])

    useEffect(() => {
      if (allGames.allGameData !== null) {
        let fileterddata = [...allGames.allGameData?.users].sort(
          (a, b) => new Date(b.created) - new Date(a.created),
        )
        setMyGameData({
          ...allGames,
          allGameData: {
            ...allGames.allGameData,
            users: fileterddata,
          },
        })
        // setFilteredData(allGames)
      }
    }, [allGames])

    useEffect(() => {
      if (data && myGameData) {
        // console.log(myGameData?.allGameData);
        const filteredGameData = myGameData?.allGameData?.users.map((gameData) => {
          return {
            speaker: gameData.speaker,
            speller: gameData.speller,
          }
        })
      }
    }, [data, myGameData])


    useEffect(() => {
        const storedData = myGameData;
        // const storedData = localStorage.getItem('myGameData');
        if (storedData) {
            setMyGameData(storedData);
            setFilteredData(storedData)
        }

        const delay = setTimeout(() => {
            setLoading(true);
        }, 1);

        return () => {
            clearTimeout(delay);
        };

    }, [myGameData]);
    const [dateFilterType, setDateFilterType] = useState('Custom')
  const [currentDate, setCurrentDate] = useState(new Date())

    const handleDateFilterTypeChange = (e) => {
      setDateFilterType(e.target.value)
      setCurrentDate(new Date())
      updateDateRange(new Date(), e.target.value)
    }
    const handlePrevDate = () => {
      let newDate;
      if (dateFilterType === 'Day') {
        newDate = subDays(currentDate, 1);
      } else if (dateFilterType === 'Week') {
        newDate = subWeeks(currentDate, 1);
      } else if (dateFilterType === 'Month') {
        newDate = subMonths(currentDate, 1);
      }
      setCurrentDate(newDate);
      updateDateRange(newDate, dateFilterType)
    };
  
    const handleNextDate = () => {
      let newDate
      if (dateFilterType === 'Day') {
        newDate = addDays(currentDate, 1)
      } else if (dateFilterType === 'Week') {
        newDate = addWeeks(currentDate, 1)
      } else if (dateFilterType === 'Month') {
        newDate = addMonths(currentDate, 1)
      }

      if (!isFutureDate(currentDate, dateFilterType)) {
        setCurrentDate(newDate)
        updateDateRange(newDate, dateFilterType)
      }
    }
  
    const isFutureDate = (date, type) => {
      const today = new Date();
      if (type === 'Day') {
        const newDate = addDays(date, 1)
        return isAfter(newDate, today);
      } else if (type === 'Week') {
        const newDate = addWeeks(date, 1)
        const startOfWeekDate = startOfWeek(newDate, { weekStartsOn: 0 });
        return isAfter(startOfWeekDate, today);
      } else if (type === 'Month') {
        const newDate = addMonths(date, 1)
        const startOfMonthDate = startOfMonth(newDate);
        return isAfter(startOfMonthDate, today);
      }
      return false
    };
  
    const updateDateRange = (date, type) => {
      if (type === 'Day') {
        setSelectedToFilterDate(date)
        setSelectedFilterDate(date)
      } else if (type === 'Week') {
        const startOfWeekDate = startOfWeek(date, { weekStartsOn: 0 })
        const endOfWeekDate = endOfWeek(date, { weekStartsOn: 0 })
        setSelectedFilterDate(startOfWeekDate)
        setSelectedToFilterDate(endOfWeekDate)
      } else if (type === 'Month') {
        const startOfMonthDate = startOfMonth(date)
        const endOfMonthDate = endOfMonth(date)
        setSelectedFilterDate(startOfMonthDate)
        setSelectedToFilterDate(endOfMonthDate)
      }
    }
  
    const renderDateDisplay = () => {
      if (dateFilterType === 'Day') {
        return (
          <span>
            <strong>{format(currentDate, 'EEEE, MMM d, yyyy')}</strong>
          </span>
        )
        // return format(currentDate, 'EEEE, MMM d, yyyy');
      } else if (dateFilterType === 'Week') {
        const startOfWeekDate = startOfWeek(currentDate, { weekStartsOn: 0 }) // Sunday as the first day
        const endOfWeekDate = endOfWeek(currentDate, { weekStartsOn: 0 })
        return (
          <span>
            <strong>{format(startOfWeekDate, 'EEE, MMM d, yyyy')}</strong> &nbsp; to &nbsp;{' '}
            <strong>{format(endOfWeekDate, 'EEE, MMM d, yyyy')}</strong>
          </span>
        )
        // return `${format(startOfWeekDate, 'EEE, MMM d, yyyy')}  to  ${format(endOfWeekDate, 'EEE, MMM d, yyyy')}`;
      } else if (dateFilterType === 'Month') {
        const startOfMonthDate = startOfMonth(currentDate)
        const endOfMonthDate = endOfMonth(currentDate)
        return (
          <span>
            <strong>{format(startOfMonthDate, 'EEE, MMM d, yyyy')}</strong> &nbsp; to &nbsp;{' '}
            <strong>{format(endOfMonthDate, 'EEE, MMM d, yyyy')}</strong>
          </span>
        )
        // return `${format(startOfMonthDate, 'EEE, MMM d, yyyy')}  to  ${format(endOfMonthDate, 'EEE, MMM d, yyyy')}`;
      }
      return null
    }
    
    const category = [
        {
            name: 'Soundalikes',
            value: 'category_soundalikes',
            color: '#ff45ed'
        },
        {
            name: 'Custom AI',
            value: 'category_custom',
            color: '#ff45ed'
        },
        {
            name: 'Amounts',
            value: 'category_amounts',
            color: '#3e48ff'
        },
        {
            name: 'Anatomy',
            value: 'category_anatomy',
            color: '#00aaff'
        },
        {
            name: 'Animals',
            value: 'category_animals',
            color: '#39b70f'
        },
        {
            name: 'Architecture',
            value: 'category_architecture',
            color: '#ff4daf'
        },
        {
            name: 'Attire',
            value: 'category_attire',
            color: '#ff3e3e'
        },
        {
            name: 'Landscapes',
            value: 'category_landscapes',
            color: '#36ce36'
        },
        {
            name: 'Random',
            icon: 'category_random',
            value: undefined,
            color: '#b20059'
        },
        {
            name: 'Dining',
            value: 'category_dining',
            color: '#efd203'
        },
        {
            name: 'Feelings',
            value: 'category_feelings',
            color: '#00baa3'
        },
        {
            name: 'Health',
            value: 'category_health',
            color: '#633eff'
        },
        {
            name: 'Home',
            value: 'category_home',
            color: '#ff5027',
            disabled: true
        },
        {
            name: 'Law',
            value: 'category_law',
            color: '#a9d100',
            disabled: true
        },
        {
            name: 'People',
            value: 'category_people',
            color: '#1569ff',
            disabled: true
        },
        {
            name: 'Sports',
            value: 'category_sports',
            color: '#ff6333',
            disabled: true
        },
        {
            name: 'Tech',
            value: 'category_tech',
            color: '#009fef',
            disabled: true
        },
        {
            name: 'Travel',
            value: 'category_travel',
            color: '#b331ff',
            disabled: true
        },
        {
            name: 'Work',
            value: 'category_work',
            color: '#ff9d00',
            disabled: true
        }
    ]

    category.sort((a, b) => {
        // Convert names to lowercase for case-insensitive sorting
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        // Compare the names
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
    });



    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const [activeAccordionItem, setActiveAccordionItem] = useState(null);

    // console.log("openDropdwon", openDropdwon, page);

    let openAccordion = (i) => {
        // console.log(i)
        setActiveAccordionItem(i)
        setOpenDropdwon(!openDropdwon)
    }

    useEffect(() => {
        if (activeAccordionItem !== null) {
            setPage(0);
        }
    }, [activeAccordionItem]);

    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value)
    }

    useEffect(() => {
      setPage(0)
      if (!filteredData?.allGameData?.users) return
      let filtered = [...filteredData?.allGameData?.users]
      // console.log(filtered , "sese")

      if (selectedMode !== 'all') {
        filtered = filtered.filter((row) => (selectedMode === 'solo' ? row.solo : !row.solo))
      }

      if (selectedCompletion !== 'all') {
        filtered = filtered.filter((row) =>
          selectedCompletion === 'complete' ? row.finished : !row.finished,
        )
      }

      if (searchTerm.trim() !== '') {
        filtered = filtered.filter(
          (row) =>
            row.speaker?.username.toLowerCase().includes(searchTerm?.trim().toLowerCase()) ||
            row.speller?.username.toLowerCase().includes(searchTerm?.trim().toLowerCase()) ||
            row.category?.toLowerCase().includes(searchTerm?.trim().toLowerCase()) ||
            row.difficulty?.toLowerCase().includes(searchTerm?.trim().toLowerCase()),
        )
      }

      const updatedGameData = {
        ...filteredData,
        allGameData: {
          ...filteredData?.allGameData,
          users: filtered,
        },
      }
      setFilteredSearchData(updatedGameData)
    }, [searchTerm, filteredData , selectedCompletion ,selectedMode])

    const handleApplyGameFilter = () => {
      setIsDateFilterApplied(true)
      setPage(0)
    if (myGameData && myGameData.allGameData && myGameData.allGameData.users) {
      // console.log(filteredData)
      let filtered = [...myGameData.allGameData.users]

      // const fromDate = new Date(formatedDate);
      // const toDate = new Date(formatedToDate);
      // toDate.setDate(toDate.getDate() + 1);
      const originalFromDate = new Date(formatedDate)
      const originalToDate = new Date(formatedToDate)
      const fromDate = format(originalFromDate, 'yyyy-MM-dd')
      const toDate = format(originalToDate, 'yyyy-MM-dd')
      // toDate.setDate(toDate.getDate() + 1) // Add 1 day to include the entire 'toDate' day
      // const formattedToDate = toDate.toISOString().split('T')[0]

      // filtered = filtered.filter((item) => {
      //   const createdAtDate = new Date(item.created)
      //   return createdAtDate >= fromDate && createdAtDate <= toDate
      // })
      filtered = filtered.filter((item) => {
        const createdAtDate = new Date(item.created).toISOString().split('T')[0]
        return createdAtDate >= fromDate && createdAtDate <= toDate
      })

      // if (selectedMode !== 'all') {
      //   filtered = filtered.filter((row) => (selectedMode === 'solo' ? row.solo : !row.solo))
      // }
      // if (selectedCompletion !== 'all') {
      //   filtered = filtered.filter((row) =>
      //     selectedCompletion === 'complete' ? row.finished : !row.finished,
      //   )
      // }

      // Update the users array within the copy of myGameData
      const updatedGameData = {
        ...myGameData,
        allGameData: {
          ...myGameData.allGameData,
          users: filtered,
        },
      }
      setFilteredData(updatedGameData)
    }
    }

    const handleResetGameFilter = () => {
      setIsDateFilterApplied(false)
      setSearchTerm('')
      setSelectedMode('all')
      setSelectedCompletion('all')
      setPage(0)
      setFilteredData(myGameData)
      const formattedDate = moment(Date.now()).format('MM-DD-YYYY')
      setFormatedDate(formattedDate)

      const formattedToDate = moment(Date.now()).format('MM-DD-YYYY')
      setFormatedToDate(formattedToDate)
      setSelectedToFilterDate(new Date())
      setSelectedFilterDate(new Date())
    }

    const handleStartDateClick = (clickOn) => {
      if (clickOn === 'fromDate') {
        setIsClickedFromDate(!isClickedFromDate)
      } else {
        setIsClickedToDate(!isClickedToDate)
      }
    }

    const handleClickOutside = (event) => {
        if (wrapperRefCalendar.current && !wrapperRefCalendar.current.contains(event.target)) {
          setIsClickedFromDate(false)
          setIsClickedToDate(false)
        }
      }
    
      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
          document.removeEventListener('mousedown', handleClickOutside)
        }
      }, [])

    // console.log('setFilteredData----filteredSearchData', filteredSearchData)
    if (allGames?.loading) {
      return (
        <>
          <div className="loaderSection">
            <div className="wrapperloader">
              <div className="circle"></div>
              <div className="circle"></div>
              <div className="circle"></div>
              <div className="shadow"></div>
              <div className="shadow"></div>
              <div className="shadow"></div>
            </div>
          </div>
        </>
      )
    }
    return (
      <div className="zoundslike_catagory_game_with_count_container">
        <div
          style={{
            display: 'flex',
            flexWrap:'wrap',
            gap: '5px',
            marginBottom: '5px',
            opacity: isDateFilterApplied ? 0.5 : 1,
            pointerEvents: isDateFilterApplied ? 'none' : 'auto',
          }}
        >
          <div>
            <select
              // ref={dateFilterSelectRef}
              onChange={handleDateFilterTypeChange}
              value={dateFilterType}
              className=""
              id="dateFilterSelectRef"
              style={{
                width: '250px',
                borderRadius: '5px',
                padding: '10px',
                backgroundColor: '#fff',
                textAlign: 'center',
              }}
            >
              <option value="Day">Day</option>
              <option value="Week">Week</option>
              <option value="Month">Month</option>
              <option value="Custom">Custom</option>
            </select>
          </div>
          {dateFilterType !== 'Custom' && (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flex: 1,
                  alignItems: 'center',
                  border: '1px solid black',
                  borderRadius: '10px',
                  backgroundColor: '#fff',
                }}
              >
                <button
                  onClick={handlePrevDate}
                  style={{
                    border: 'none',
                    display: 'flex',
                    borderRadius: '9px 0px 0px 9px',
                    backgroundColor: '#fff',
                    padding: '5px 10px',
                    color: '#000',
                  }}
                >
                  {' '}
                  <CIcon icon={cilArrowThickLeft} size="xxl" />
                </button>
                <label
                  style={{
                    padding: '8px',
                  }}
                  htmlFor="dateFilterSelectRef"
                >
                  {renderDateDisplay()}
                </label>
                <button
                  onClick={handleNextDate}
                  style={{
                    display: 'flex',
                    border: 'none',
                    borderRadius: '0px 9px 9px 0px',
                    backgroundColor: '#fff',
                    padding: '5px 10px',
                    color: '#000',
                    opacity: isFutureDate(currentDate, dateFilterType) ? '0.5' : '1',
                    cursor: isFutureDate(currentDate, dateFilterType) ? 'not-allowed' : 'pointer',
                  }}
                  disabled={isFutureDate(currentDate, dateFilterType)}
                >
                  <CIcon icon={cilArrowThickRight} size="xxl" />{' '}
                </button>
              </div>
            </>
          )}
          {dateFilterType === 'Custom' && (
            <>
              <div className="zoundslike_games_from_date_and_to_date_syn">
                <div
                  ref={wrapperRefCalendar}
                  onClick={() => handleStartDateClick('fromDate')}
                  className="zoundslike_games_date_filter_syn"
                  style={{ backgroundColor: '#fff' }}
                >
                  From: {formatedDate}
                </div>
                {isClickedFromDate && (
                  <div
                    className="dropdown-games-to-from-calender-container"
                    ref={wrapperRefCalendar}
                  >
                    <Calendar
                      maxDate={selectedToFilterDate}
                      calendarType="US"
                      locale="en"
                      onChange={setSelectedFilterDate}
                      value={selectedFilterDate}
                      formatShortWeekday={(locale, date) => {
                        const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
                        return days[date.getDay()]
                      }}
                      formatMonthYear={(locale, date) => {
                        const options = { month: 'short', year: 'numeric' }
                        return new Intl.DateTimeFormat(locale, options).format(date)
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="zoundslike_games_from_date_and_to_date_syn">
                <div
                  ref={wrapperRefCalendar}
                  onClick={() => handleStartDateClick('toDate')}
                  className="zoundslike_games_date_filter_syn"
                  style={{ backgroundColor: '#fff' }}
                >
                  To: {formatedToDate}
                </div>
                {isClickedToDate && (
                  <div
                    className="dropdown-games-to-from-calender-container"
                    ref={wrapperRefCalendar}
                  >
                    <Calendar
                      minDate={selectedFilterDate}
                      maxDate={new Date()}
                      calendarType="US"
                      locale="en"
                      onChange={setSelectedToFilterDate}
                      value={selectedToFilterDate}
                      formatShortWeekday={(locale, date) => {
                        const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
                        return days[date.getDay()]
                      }}
                      formatMonthYear={(locale, date) => {
                        const options = { month: 'short', year: 'numeric' }
                        return new Intl.DateTimeFormat(locale, options).format(date)
                      }}
                    />
                  </div>
                )}
              </div>
            </>
          )}
          <div>
            <button
              style={{
                background: '#175676',
                color: 'white',
                padding: '8px 20px',
                border: 'none',
                borderRadius: '4px',
              }}
              onClick={handleApplyGameFilter}
            >
              Apply Date Filters
            </button>
          </div>
        </div>
        <div style={{ display: 'flex', gap: '7px', marginBottom: '20px', flexWrap: 'wrap' }}>
          <div className="game_searchBar">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <span>{SearchIcon}</span>
          </div>
          <div className="games_difficulty_select_box">
            <select
              value={selectedMode}
              onChange={(e) => setSelectedMode(e.target.value)}
              style={{ backgroundColor: '#fff' }}
            >
              <option value="all">Mode</option>
              <option value="solo">Solo</option>
              <option value="partner">Partner</option>
            </select>
          </div>
          <div className="games_difficulty_select_box">
            <select
              value={selectedCompletion}
              onChange={(e) => setSelectedCompletion(e.target.value)}
              style={{ backgroundColor: '#fff' }}
            >
              <option value="all">Status</option>
              <option value="complete">Completed</option>
              <option value="not complete">Not completed</option>
            </select>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
          <p style={{ margin: '0px' }}>
            Total Results:
            <span style={{ fontWeight: '700' }}>
              {filteredSearchData?.allGameData?.users?.length}
            </span>
          </p>
          {filteredSearchData?.allGameData?.users?.length !==
            allGames.allGameData?.users?.length && (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button
                style={{
                  background: '#175676',
                  color: 'white',
                  padding: '8px 20px',
                  border: 'none',
                  borderRadius: '4px',
                }}
                onClick={handleResetGameFilter}
              >
                Reset Filters
              </button>
            </div>
          )}
        </div>
        <CAccordion activeItemKey={activeAccordionItem}>
          {category &&
            category
              .sort((a, b) => {
                const countA =
                  filteredSearchData?.allGameData.users.filter((game) => game.category === a.value)
                    .length ?? 0
                const countB =
                  filteredSearchData?.allGameData.users.filter((game) => game.category === b.value)
                    .length ?? 0
                return countB - countA
              })
              .map((catagoryValue, index) => {
                let count = 0

                const categoryGames =
                  filteredSearchData !== null
                    ? filteredSearchData.allGameData.users.filter(
                        (game) => game.category === catagoryValue.value,
                      )
                    : []

                const formattedDateGames = categoryGames.map((game) => {
                  const dateArray = game.created.split('T')[0].split('-')
                  const formattedDate = `${dateArray[1]}-${dateArray[2]}-${dateArray[0]}`
                  return { ...game, created: formattedDate }
                })
                count = formattedDateGames.length
                rows.push(formattedDateGames)
                // console.log('categoryGames', categoryGames)
                return (
                  <div
                    className={
                      loading && filteredSearchData !== null
                        ? 'zoundslike_catagory_game_with_count'
                        : 'zoundslike_catagory_game_with_count_'
                    }
                    key={catagoryValue.value}
                  >
                    <CAccordionItem onClick={() => openAccordion(index)} itemKey={index}>
                      <CAccordionHeader>
                        <h3 className="zoundslike_catagory_name_count_snr" key={index}>
                          {catagoryValue.name} : {count}
                        </h3>
                      </CAccordionHeader>
                      <CAccordionBody>
                        {loading && filteredSearchData !== null ? (
                          <>
                            {count !== 0 && (
                              <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: 440 }}>
                                  <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Speaker</TableCell>
                                        <TableCell>Speller</TableCell>
                                        <TableCell>Difficulty</TableCell>
                                        <TableCell>Mode</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Guesses</TableCell>
                                        <TableCell>Correct Guesses</TableCell>
                                        <TableCell>Incorrect Guesses</TableCell>
                                        <TableCell style={{ width: '130px' }}>Played On</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {formattedDateGames
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, rowIndex, i) => (
                                          <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={rowIndex}
                                          >
                                            <TableCell align="left">
                                              {rowIndex + 1 + page * rowsPerPage}
                                            </TableCell>
                                            <TableCell align="left">
                                              {row.speaker?.username === 'solo'
                                                ? 'zoundslike'
                                                : row.speaker?.username || 'Deleted User'}
                                            </TableCell>
                                            <TableCell align="left">
                                              {row.speller?.username || 'Deleted User'}
                                            </TableCell>
                                            <TableCell align="left">
                                              {row.difficulty ? row.difficulty : 'NA'}
                                            </TableCell>
                                            <TableCell align="left">
                                              {row.solo ? 'Solo' : 'Partnered'}
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              style={{ color: `${row.finished ? 'green' : 'red'}` }}
                                            >
                                              {row.finished ? 'Completed' : 'Not Completed'}
                                            </TableCell>
                                            <TableCell align="left">{row.numGuesses}</TableCell>
                                            <TableCell align="left" style={{ color: 'green' }}>
                                              {row.numCorrectGuesses}
                                            </TableCell>
                                            <TableCell align="left" style={{ color: 'red' }}>
                                              {row.numGuesses - row.numCorrectGuesses}
                                            </TableCell>
                                            <TableCell align="left">{row.created}</TableCell>
                                          </TableRow>
                                        ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                                <TablePagination
                                  //  rowsPerPageOptions={[10, 25, 100]}
                                  component="div"
                                  count={formattedDateGames && formattedDateGames.length}
                                  rowsPerPage={rowsPerPage}
                                  page={page}
                                  onPageChange={handleChangePage}
                                  onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                              </Paper>
                            )}
                          </>
                        ) : (
                          <div className="zoundslike_loading_syn"></div>
                        )}
                      </CAccordionBody>
                    </CAccordionItem>
                  </div>
                )
              })}
        </CAccordion>
      </div>
    )
}

export default Games
